import * as React from 'react';
import { formattedDate } from '../../../common/helpers/date';
import { useTranslations } from '../../../common/translation';
import { HeadTitle } from '../../../components/head-title/HeadTitle';
import { Img } from '../../../components/img/Img';
import { categoryList } from '../common';
import { useInitializeNews } from '../hooks/useInitializeNews';
import { NewsProps } from '../NewsLayout.props';
import {
  StyledNews,
  StyledNewsContent,
  StyledNewsDate,
  StyledNewsHeader,
  StyledNewsImageContainer,
  StyledNewsSubtitle,
  StyledNewsTitle,
} from '../NewsLayout.styles';
import translations from '../translations';

export const Article = ({ body, categories, date, image, subtitle, timezone, title }: NewsProps) => {
  const { locale, t } = useTranslations(translations);
  const localeDate = typeof date !== 'undefined' ? formattedDate(+date * 1000, locale, 'news', timezone) : '';

  useInitializeNews();

  return (
    <StyledNews data-component='article'>
      <HeadTitle title={`${title} | ${t('articlePageTitle', 'Articles')}`} />
      <StyledNewsHeader>
        {categoryList(categories, t)}
        {localeDate && <StyledNewsDate data-testid='news-date'>{localeDate}</StyledNewsDate>}
        <StyledNewsTitle>{title}</StyledNewsTitle>
        {subtitle && <StyledNewsSubtitle>{subtitle}</StyledNewsSubtitle>}
      </StyledNewsHeader>
      <StyledNewsContent>
        {image && (
          <StyledNewsImageContainer>
            <Img
              alt={image.alt || `${title} - ${t('articleImageAltFallback', 'Banner')}`}
              src={image.src}
              title={image.title}
            />
          </StyledNewsImageContainer>
        )}
        <div dangerouslySetInnerHTML={{ __html: body }} />
      </StyledNewsContent>
    </StyledNews>
  );
};
