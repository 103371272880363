import * as React from 'react';
import { useEffect } from 'react';
import { useRouteData } from 'react-static';
import { pushToDataLayer } from '../../common/helpers/dataLayer';
import { useDebug } from '../../common/hooks/useDebug';
import { AdditionalResources } from '../../components/additional-resources/AdditionalResources';
import { DefaultLayout } from '../default/DefaultLayout';
import { Article } from './article/Article';
import { NewsLayoutProps } from './NewsLayout.props';
import { StyledNewsBody, StyledNewsContainer, StyledNewsSidebar } from './NewsLayout.styles';
import { PressRelease } from './press-release/PressRelease';
import { RecentNews } from './recent-news/RecentNews';
import { useGetRecentNews } from './recent-news/useGetRecentNews';

// noinspection JSUnusedGlobalSymbols
export default () => {
  const { locale, newsProps, translatedPaths } = useRouteData<NewsLayoutProps>();
  const { recentItemsQueueName, seo, title, type } = newsProps;
  const News = type === 'Articles' ? Article : PressRelease;
  const { data } = useGetRecentNews(recentItemsQueueName, locale);

  useDebug('app:layout:NewsLayout', { props: { locale, newsProps } });

  useEffect(() => {
    pushToDataLayer({ title, contentType: type });
  }, []);

  return (
    <DefaultLayout locale={locale} seo={seo} translatedPaths={translatedPaths}>
      <StyledNewsContainer>
        <StyledNewsBody>
          <News {...newsProps} key={title} />
        </StyledNewsBody>
        <StyledNewsSidebar>
          {data && <RecentNews nodes={data.nodes} type={type} />}
          <AdditionalResources type={type} />
        </StyledNewsSidebar>
      </StyledNewsContainer>
    </DefaultLayout>
  );
};
