import * as React from 'react';
import { TranslationFn } from '../../common/translation';
import { StyledNewsCategory, StyledNewsCategoryContainer } from './NewsLayout.styles';

export const categoryList = (categories: string[] | undefined, t: TranslationFn) =>
  (categories || []).length > 0 ? (
    <StyledNewsCategoryContainer>
      {categories!.map((category: string, index: number) => (
        <StyledNewsCategory category={category} key={`${category}-${index}`}>
          {t(category, category)}
        </StyledNewsCategory>
      ))}
    </StyledNewsCategoryContainer>
  ) : null;
