import { Link } from '@reach/router';
import * as React from 'react';
import { Routes } from '../../../common/constants';
import { getPrefixRedirectedUrl } from '../../../common/helpers/prefix';
import { useTranslations } from '../../../common/translation';
import { Button } from '../../../components/button/Button';
import translations from '../translations';
import { RecentNewsProps } from './RecentNews.props';
import {
  StyledButtonContainer,
  StyledRecentNews,
  StyledRecentNewsItem,
  StyledRecentNewsList,
  StyledRecentNewsTitle,
} from './RecentNews.styles';

const recentNewsList = (nodes: any) => (
  <StyledRecentNewsList>
    {(nodes || []).map((node: any) => (
      <StyledRecentNewsItem key={node.title}>
        <Link to={node.path}>
          <span>{node.title}</span>
        </Link>
      </StyledRecentNewsItem>
    ))}
  </StyledRecentNewsList>
);

export const RecentNews = ({ nodes, type }: RecentNewsProps) => {
  const { t } = useTranslations(translations);

  let title: string;
  let noNewsText: string;
  let buttonText: string;
  let listPath: string;
  if (type === 'Articles') {
    title = t('articlesTitle', 'Latest Articles');
    noNewsText = t('noArticlesText', 'No Recent Articles are available');
    buttonText = t('allArticlesButton', 'All Articles');
    listPath = getPrefixRedirectedUrl(Routes.articles);
  } else {
    title = t('pressReleasesTitle', 'Latest Press Releases');
    noNewsText = t('noPressReleasesText', 'No Recent Press Releases are available');
    buttonText = t('allPressReleasesButton', 'All Press Releases');
    listPath = getPrefixRedirectedUrl(Routes.pressReleases);
  }

  return (
    <StyledRecentNews data-component='recent-news'>
      <StyledRecentNewsTitle>{title}</StyledRecentNewsTitle>
      {(nodes || []).length ? recentNewsList(nodes) : <p>{noNewsText}</p>}
      <StyledButtonContainer>
        <Button aria-label={buttonText} fullWidthBreakpoint={{ min: 'tablet' }} href={listPath}>
          {buttonText}
        </Button>
      </StyledButtonContainer>
    </StyledRecentNews>
  );
};
