import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../common/breakpoints';
import { black, darkGrey2, getTagColor, grey2, lightBlue, lightGrey1 } from '../../common/colors';
import { StyledPaddingContainer } from '../../common/padding.styles';

export const StyledNewsContainer = styled(StyledPaddingContainer)`
  max-width: 1600px;
  margin: 0 auto;
  padding-top: 60px;
  padding-bottom: 60px;

  @media print {
    margin: 0;
    padding: 0;
  }
`;

export const StyledNewsBody = styled.div`
  width: 100%;
  display: inline-block;
  vertical-align: top;

  ${mediaQueryWidth({ min: 'tablet' })`
    width: 70%;
  `}

  @media print {
    width: 100%;
  }
`;

export const StyledNewsSidebar = styled.div`
  width: 100%;
  display: inline-block;
  vertical-align: top;

  ${mediaQueryWidth({ min: 'tablet' })`
    width: 30%;
  `}

  @media print {
    display: none;
  }
`;

export const StyledNews = styled.article`
  .rtecenter {
    text-align: center;
  }

  @media print {
    margin: 20px;
    padding-bottom: 0;
  }

  strong {
    font-weight: 800;
  }
`;

export const StyledNewsHeader = styled.header`
  border-left: 1px solid ${lightGrey1};
  padding-left: 25px;
  margin-bottom: 25px;
`;

export const StyledNewsCategory = styled.div<{ category?: string }>`
  display: inline-block;
  padding: 0 ${rem(5)};
  background-color: ${({ category }) => getTagColor(category)};
  color: ${black};
`;

export const StyledNewsCategoryContainer = styled.div`
  margin-bottom: ${rem(10)};

  & > ${StyledNewsCategory} {
    margin-bottom: ${rem(5)};

    &:not(:last-of-type) {
      margin-right: ${rem(5)};
    }
  }
`;

export const StyledNewsDate = styled.div`
  margin-bottom: ${rem(15)};
  font-size: ${rem(11)};
  font-weight: 300;
  line-height: 0.9em;
  color: ${grey2};

  ${mediaQueryWidth({ min: 'tablet' })`
    font-size: ${rem(18)};
  `};
`;

export const StyledNewsTitle = styled.h1`
  margin: 0;
  font-size: ${rem(30)};
  font-weight: 800;
  line-height: ${rem(24)};
  color: ${darkGrey2};
  text-transform: none;

  ${mediaQueryWidth({ min: 'tablet' })`
    font-size: ${rem(40)};
    line-height: ${rem(38)};
  `}

  &:not(:last-child) {
    margin: 0 0 ${rem(20)} 0;
  }
`;

export const StyledNewsSubtitle = styled.h2`
  margin: 0;
  font-size: ${rem(20)};
  font-weight: 800;
  line-height: ${rem(20)};
  color: ${darkGrey2};
  text-transform: none;

  ${mediaQueryWidth({ min: 'tablet' })`
    font-size: ${rem(20)};
    line-height: ${rem(24)};
  `}

  &:not(:last-child) {
    margin: 0 0 ${rem(16)} 0;
  }
`;

export const StyledNewsImageContainer = styled.div`
  width: 100%;

  img {
    height: auto;
    width: 100%;
  }
`;

export const StyledNewsContent = styled.section`
  .rtecenter {
    text-align: center;
  }

  a {
    color: ${lightBlue};
  }

  h1,
  h2,
  h3,
  h4,
  p {
    margin: 0.5rem 0;
  }

  p:empty {
    margin: 0;
    display: none;
  }

  ul {
    list-style: disc;
    margin-left: 20px;
  }

  figure figcaption {
    font-size: 0.75rem;
    color: ${grey2};
  }

  img {
    margin-top: 5px;
    margin-bottom: 5px;

    &[style*='float:right'] {
      margin-left: 0.5rem;
    }

    &[style*='float:left'] {
      margin-right: 0.5rem;
    }
  }

  ${mediaQueryWidth({ max: 'tablet' })`
    img {
      height: auto;
      width: 100%;
      margin: 5px 10px 5px 0;
    }

    twitterwidget, twitter-widget {
      width: auto !important;
    }
  `}

  blockquote.instagram-media {
    p,
    p a {
      color: ${grey2} !important;
    }
  }

  // Provides enough vertical space for the first 5 songs in the playlist
  .spotify-iframe-wrapper iframe {
    width: 100%;
    height: 340px;
  }

  .non-yt-iframe-wrapper,
  .yt-iframe-wrapper,
  .spotify-iframe-wrapper {
    width: 75%;
    padding-bottom: 5px;

    .fluid-width-video-wrapper {
      padding-top: 57.25% !important;
    }
  }

  iframe .timestampContent {
    color: ${black} !important;
  }
`;
