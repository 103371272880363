import { useEffect } from 'react';
import { loadInstagramEmbed } from '../../../common/helpers/externalScripts';

// tslint:disable-next-line:no-var-requires
const fitvids = require('fitvids');

export const useInitializeNews = () => {
  const setMaxWidth = (element: HTMLElement, width: string) => {
    if (width) {
      element.style.maxWidth = width.indexOf('px') < 0 && width.indexOf('%') < 0 ? `${width}px` : width;
    }
  };

  const setMaxHeight = (element: HTMLElement, height: string) => {
    if (height) {
      element.style.maxHeight = height.indexOf('px') < 0 && height.indexOf('%') < 0 ? `${height}px` : height;
    }
  };

  const wrapElement = (element: any, wrapperTag: string, wrapperClass?: string) => {
    const wrapper = document.createElement(wrapperTag);
    if (wrapperClass) {
      wrapper.className = wrapperClass;
    }
    setMaxWidth(wrapper, element.width);
    setMaxHeight(wrapper, element.height);
    element.parentNode.insertBefore(wrapper, element);
    wrapper.appendChild(element);
  };

  const initIframes = () => {
    let className: string;
    Array.from(document.querySelectorAll('iframe')).forEach((iframe) => {
      const src = iframe.src;
      if (src.includes('youtube')) {
        className = 'yt-iframe-wrapper';
      } else if (src.includes('spotify.com')) {
        className = 'spotify-iframe-wrapper';
      } else if (src.indexOf('ellentube.com') >= 0) {
        className = 'non-yt-iframe-wrapper';
      } else {
        className = '';
      }

      if (className) {
        setMaxHeight(iframe, iframe.height);
        setMaxWidth(iframe, iframe.width);
        wrapElement(iframe, 'div', className);
      }
    });
  };

  const initFitVids = () => {
    fitvids('.non-yt-iframe-wrapper', {
      players: ['iframe[src*="ellentube.com"]'],
    });

    fitvids('.yt-iframe-wrapper', {
      players: ['iframe[src*="youtube.com"]'],
    });

    fitvids('.instagram-media', {
      players: ['iframe[src*="instagram.com"]'],
    });
  };

  const initInstagram = () => {
    const blockQuotes = document.querySelectorAll('blockquote.instagram-media');
    if ((blockQuotes || []).length) {
      loadInstagramEmbed(window);
    }
  };

  useEffect(() => {
    initIframes();
    initFitVids();
    initInstagram();
  }, []);
};
