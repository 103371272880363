import { useEffect, useState } from 'react';
import { buildQueueName } from '../../../common/helpers/carousel';
import { getPrefixRedirectedUrl } from '../../../common/helpers/prefix';
import { useGet } from '../../../common/hooks/useGet';
import { RecentNewsItem, RecentNewsItemNodes } from './RecentNews.props';

export const processData = ({ nodes, ...otherFields }: RecentNewsItemNodes): RecentNewsItemNodes => ({
  ...otherFields,
  nodes: nodes.map((node: RecentNewsItem) => ({
    ...node,
    path: getPrefixRedirectedUrl(node.path),
  })),
});

export const useGetRecentNews = (recentItemsQueueName: string, locale?: string) => {
  const queueName = buildQueueName(recentItemsQueueName, locale);
  const [processedData, setProcessedData] = useState<RecentNewsItemNodes>();

  const { data, error, loading } = useGet(`/queues/${queueName}.json`, { nodes: [] });

  useEffect(() => {
    if (data && Array.isArray(data.nodes)) {
      setProcessedData(processData(data));
    }
  }, [data]);
  return { error, loading, data: processedData };
};
