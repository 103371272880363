import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../../common/breakpoints';
import { lightGrey1, white } from '../../../common/colors';

export const StyledRecentNews = styled.section`
  margin: ${rem(50)} 0 0 0;
  background-color: ${white};

  ${mediaQueryWidth({ min: 'tablet' })`
    margin: 0;
    padding: 0 ${rem(20)};
  `}
`;

export const StyledButtonContainer = styled.div`
  text-align: center;
`;

export const StyledRecentNewsTitle = styled.h2`
  font-size: ${rem(20)};
  margin: 0 0 ${rem(20)} 0;
  text-transform: capitalize;

  ${mediaQueryWidth({ min: 'tablet' })`
    font-size: ${rem(30)};
  `}
`;

export const StyledRecentNewsList = styled.div`
  margin: 0 0 ${rem(20)} 0;
  border-bottom: 1px solid ${lightGrey1};
`;

export const StyledRecentNewsItem = styled.div`
  line-height: 1.625em;
  border-top: 1px solid ${lightGrey1};
  padding: ${rem(10)} ${rem(10)} ${rem(10)} 0;

  &:hover,
  &:active,
  &:focus {
    background: none;
  }

  span {
    font-size: ${rem(14)};
    font-weight: 300;
  }
`;
