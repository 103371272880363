import { SvgIcon } from '@material-ui/core';
import * as React from 'react';
import WaterTowerIcon from '../../assets/images/icons/svg/water-tower.svg';
import { useWindowResize } from '../../common/hooks/useWindowResize';
import { useTranslations } from '../../common/translation';
import { Button } from '../button/Button';
import { AdditionalResourcesProps } from './AdditionalResources.props';
import { AddResLargeIcon, AddResRow, StyledAdditionalResources } from './AdditionalResources.styles';
import translations from './translations';

// tslint:disable:max-line-length
export const PrintIcon = (
  <SvgIcon viewBox='0 0 64 64'>
    <path d='M62.52,53.87H50.39v-3H61v-32H2.95v32h9.86v3H1.45a1.5,1.5,0,0,1-1.5-1.5v-35a1.5,1.5,0,0,1,1.5-1.5H62.52a1.5,1.5,0,0,1,1.5,1.5v35A1.5,1.5,0,0,1,62.52,53.87Z' />
    <path d='M62.52,53.87H50.39v-3H61v-32H2.95v32h9.86v3H1.45a1.5,1.5,0,0,1-1.5-1.5v-35a1.5,1.5,0,0,1,1.5-1.5H62.52a1.5,1.5,0,0,1,1.5,1.5v35A1.5,1.5,0,0,1,62.52,53.87Z' />
    <path d='M52.74,16.42h-3V10l-7-7H14.23V16.42h-3V1.5A1.5,1.5,0,0,1,12.73,0H43.4a1.5,1.5,0,0,1,1.06.44L52.3,8.28a1.5,1.5,0,0,1,.44,1.06Z' />
    <path d='M51.24,64H12.73a1.5,1.5,0,0,1-1.5-1.5V41.09a1.5,1.5,0,0,1,1.5-1.5H51.24a1.5,1.5,0,0,1,1.5,1.5V62.52A1.5,1.5,0,0,1,51.24,64Zm-37-3H49.74V42.59H14.23Z' />
    <path d='M43.76,49.64H20.2a1,1,0,0,1,0-2H43.76a1,1,0,0,1,0,2Z' />
    <path d='M38.94,56.07H20.21a1,1,0,1,1,0-2H38.94a1,1,0,0,1,0,2Z' />
  </SvgIcon>
);
// tslint:enable:max-line-length

const windowPrint = () => window.print();

export const AdditionalResources = ({ type }: AdditionalResourcesProps) => {
  const { locale, t } = useTranslations(translations);
  const { isMobileUserAgent } = useWindowResize();

  const rows = [];
  if (!isMobileUserAgent) {
    rows.push(
      <AddResRow key='print'>
        <Button fullWidthBreakpoint={{ min: 'tablet' }} leftAlign={true} onClick={windowPrint}>
          {PrintIcon}
          {t('printButtonText', 'Print')}
        </Button>
      </AddResRow>,
    );
  }

  if (locale === 'en-US' && type === 'Press Releases') {
    rows.push(
      <AddResRow key='press-contacts'>
        <Button fullWidthBreakpoint={{ min: 'tablet' }} href={'/news/press-releases/press-contacts'} leftAlign={true}>
          <AddResLargeIcon>
            <WaterTowerIcon />
          </AddResLargeIcon>
          Press Contacts
        </Button>
      </AddResRow>,
    );
  }

  if (!rows.length) {
    return null;
  }

  return (
    <StyledAdditionalResources>
      <h2>{t('title', 'Additional Resources')}</h2>
      {rows}
    </StyledAdditionalResources>
  );
};
