export const loadScript = (id: string, src: string, callback: any) => {
  const existingScript = document.getElementById(id);

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = src;
    script.id = id;
    document.body.appendChild(script);

    script.onload = () => {
      if (callback) {
        callback();
      }
    };
  }

  if (existingScript && callback) {
    callback();
  }
};

export const loadInstagramEmbed = (window: any) => {
  loadScript('instagramScript', 'https://www.instagram.com/embed.js', () => window.instgrm.Embeds.process());
};
