import { rem } from 'polished';
import styled from 'styled-components';
import { mediaQueryWidth } from '../../common/breakpoints';
import { darkGrey2 } from '../../common/colors';

export const StyledAdditionalResources = styled.section`
  margin: ${rem(50)} 0 0 0;

  h2 {
    font-size: ${rem(20)};
    margin: 0 0 ${rem(20)} 0;
    text-transform: none;
  }

  ${mediaQueryWidth({ min: 'tablet' })`
    padding: 0 ${rem(20)};

    h2 {
      font-size: ${rem(30)};
    }
  `}
`;

export const AddResRow = styled.div`
  width: 100%;

  &:not(:last-child) {
    margin: 0 0 ${rem(15)} 0;
  }

  & > a {
    cursor: pointer;
    color: ${darkGrey2};
  }
`;

export const AddResLargeIcon = styled.div`
  & > svg {
    transform: scale(1.5);
  }
`;
